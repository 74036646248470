import * as React from "react";
import { Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { TrackingContext } from "../context";
import { useEffect } from "react";
import { FilterBar } from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const listaStato =[
  { value:"CON", text:"Consegnato"},
  { value:"INC", text:" In consegna"},
  { value:"ALTRI", text:"In lavorazione"},
]


const Tracking = () => {
  const { t } = useTranslation("common",{ keyPrefix: 'tracking' })
  const [pageResetVar, setPageResetVar] = useState(false);
 /*  const [checkBoxSelectionPrint, setCheckBoxSelectionPrint] = useState([]); */

  const {
    state: { trackingList, columnsCustom, recordCount, filter, resettaFiltri, azioni},
    getTrackingList,
    aggiornaFiltri,
    resetFiltri,
    trackEmail
    
  } = useContext(TrackingContext);

  const navigate = useNavigate();

  useEffect(() => {
    titlePage("", t("titlePage"));
    getTrackingList(
      "",
      limit,
      offset,
      navigate,
      [],
      recordCount,
      false,
      filter
    );
  }, []);

  const emailActionTrak=(e)=>{
    trackEmail(e)
   }

  return (
    <Grid container spacing={2}>
      <Grid container item mr={2} spacing={2} direction={"row"}>
        <Grid item xs={12} md={12} lg={12}>
          <FilterBar
            type={true}
            recordCount={recordCount}
            filter={filter}
            aggiornaFiltri={aggiornaFiltri}
            getMethod={getTrackingList}
            resetFiltri={resetFiltri}
            setViewStatoWeb={true}
            setViewAdvFilter={true}
            setPageResetVar={setPageResetVar}
            listStatoWeb={listaStato}
          ></FilterBar>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {columnsCustom.length > 0 ? (
          <CustomPaginationActionsTable
            /* setCheckBoxSelectionPrint={setCheckBoxSelectionPrint} */
            rows={trackingList}
            columns={columnsCustom}
            limit={100}
            navigate={navigate}
            getMethodForList={getTrackingList}
            recordCount={recordCount}
            routeDetail={"/tracking"}
            idElement={"PrgInterno"}
            pageReset={pageResetVar}
            actionShow={true}
            viewAction={{
              mail: azioni?.email?.visible,
              view: true,
              location: false,
              print: false,
              edit:azioni?.modifica?.visible,
              delete:azioni?.annulla?.visible,
            }}
            emailAction={(e)=>emailActionTrak(e)}
            filterList={filter}
            resettaFiltri={resettaFiltri}
          />
        ) : (
          <Stack justifyContent={"center"} alignContent="center">
            <Typography>{t("loadPage")}</Typography>
            <LinearProgress color="success" />
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default Tracking;
