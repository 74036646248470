import {
    Alert,
    AlertTitle,
    Box,
    Grid,
    LinearProgress,
    Snackbar,
    Table,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Stack,
    Button
  } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AuthContext, PreventiviNewContext } from "../context";
import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { RiepilogoPreventivo} from "../components/PreventiviFormComponent/"
import { CurrencySimbol } from "../utilities";





const DettaglioPreventivo = () => {
    const [locationMap, setLocationMap] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setLocationMap(false);
      };

    const{
        state:{
            form,
            result
        }
    } = useContext(PreventiviNewContext);


    function fillMittente() {
     
      return(
        `${form?.MittLocalita}<br>${form?.MittNazione}`        
      );
    }

    function fillDestinatario(){
        return(
            `${form?.DestLocalita}<br>${form?.DestNazione}`
        );
    }


// style={{borderBottom:"none"}}
    function fillImporto(){
      return (
        <TableContainer >
          {/* <strong>Riepilogo merce</strong> */}
          <Table >
            <TableHead>
              <TableRow>
                <TableCell align="right">Voce</TableCell>
                <TableCell align="right">Importo &nbsp;({CurrencySimbol(result[0].Valuta)})</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
                {/* genTableRowsPreventivo()*/ } 
                 {result[0].imprighe.map((row) => (
                <TableRow
                  //   key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="right" style={{borderBottom:"none"}}>
                    {row.Voce}
                  </TableCell>
                  <TableCell align="right" style={{borderBottom:"none"}}>
                    {row.Importo}
                  </TableCell>

                </TableRow>
              ))}

              <Button variant="text" >Totale: {result[0].Importo}{CurrencySimbol(result[0].Valuta)} </Button>

            </TableBody>
          </Table>
        </TableContainer>
      );
    }


  return (
    <Grid>
<   Grid container spacing={0}>
      <Snackbar
        open={locationMap}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Coordinate non presenti
        </Alert>
      </Snackbar>
      {form?.MittLocalita !== "" ? (
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="info" icon={<></>} sx={{ background: (theme) => theme.palette.error.light + "25" }}>
            <AlertTitle><strong>Mittente</strong></AlertTitle>
            {
              <ReactMarkdown
                children={form ? fillMittente() : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
    {/* <ArrowForwardIosIcon/> */}
    {form?.DestLocalita !== "" ? (
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="success" icon={<></>}>
          <AlertTitle><strong>Destinatario</strong></AlertTitle>
            {
              <ReactMarkdown
                children={form ? fillDestinatario() : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
    <RiepilogoPreventivo  />

    <Grid item xs={12} md={6} lg={6}>
      <Alert severity="info" icon={<></>} sx={{ background: (theme) => theme.palette.error.light + "25" }}>
        <AlertTitle><strong>Dettaglio Preventivo</strong></AlertTitle>
        <Grid item xs={12} md={6} lg={6}>
              <Alert severity="warning" icon={<></>}>
                  {fillImporto()}
              </Alert>
        </Grid>
      </Alert>
     </Grid>
    </Grid>
  )
}

export default DettaglioPreventivo