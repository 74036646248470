import React, {useState, useContext}from 'react'
import { CancelPresentationOutlined, Close } from "@mui/icons-material";
import {
    Dialog,
    Slide,
    DialogContent,
    Card,
    CardHeader,
    IconButton,
    Stack,
    Button,
    CardActions,
    CardContent,
    Grid,
    Box

  } from "@mui/material";
  import DescriptionIcon from '@mui/icons-material/Description';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentiGroups = ({ setIsOpen, setSelectedGroup, groupsList}) => {

    const handleSelectedGroup = (group) => {
        setIsOpen(true);
        setSelectedGroup(group)
      };

    function generateCard(element){       
       return (
        <div>
            <Grid item xs={12} md={12} lg={12} sx={{minWidth:500, mr:2, ml:2, mt:2, mb:2}}>
                
                <Card
                    elevation={1}
                    //  sx={{
                    //  border: 2,
                    //  borderColor: (theme) => theme.palette.secondary.main,
                    //  }}
                    
                >
                    <CardHeader
                        title={element.descrizione}
                        subheader={element.quanti ==='1'? (element.quanti+" documento."):(element.quanti+" documenti.")} 
                        align="center"
                        
                    />
                    <CardContent align="center">
                        <Box textAlign="center" >
                            <CardActions style={{justifyContent: 'center'}}>
                                <Button
                                    size={"medium"}
                                    variant="contained"
                                    startIcon={<DescriptionIcon/>}
                                    onClick={()=>{handleSelectedGroup(element.descrizione)}}
                                >
                                    Visualizza
                                </Button>
                            </CardActions>
                        </Box>
                        </CardContent>
                </Card>
            </Grid>
        </div>
       )

    }
  return (
    <div>
        <Card> 
            <CardContent>
                <Grid >
                    <Grid container spacing={0} >
                        {groupsList.map((element) => generateCard(element))}
                    </Grid>
                </Grid>
            </CardContent>
        </Card> 

    </div>
  )
}

export default DocumentiGroups