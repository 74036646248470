import React from 'react';
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Checkbox,
    Button,
    DialogActions,
    Slide,

 } from '@mui/material'
 import {useContext, useState } from 'react';
 import { TrackingContext } from '../context';

 
//transizione per dialog creazione distinte
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const CreazioneDistintaDialog = ({isOpen, setIsOpen, handleConferma,creaRitiroChecked, setCreaRitiroChecked, handleAnnulla}) => {

    const {
        state:{countNoDistinta},
      } = useContext(TrackingContext);

    const handleCheckboxRitiri = (event) => {
        setCreaRitiroChecked(event.target.checked)
      };

    return (
        <div>
        <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAnnulla}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>{"Conferma creazione distinta"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
            Confermare la creazione della distinta?            
            <br/><br/>
            <Checkbox
            checked={creaRitiroChecked}
            onChange={handleCheckboxRitiri}
            inputProps={{ 'aria-label': 'controlled' }}
            />
            Creare anche il ritiro
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleAnnulla}>Annulla</Button>
        <Button onClick={handleConferma}>Conferma</Button>
        </DialogActions>
    </Dialog>
    </div>
    )
}

export default CreazioneDistintaDialog