import { TravelExploreTwoTone } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { OrdiniRitiroContext } from "../../../context";
import { useForm } from "../../../hooks";

const Servizio = ({ mainForm, setMainForm }) => {
  const {
    state: { servizi },
    getServizibyRitiri,
  } = useContext(OrdiniRitiroContext);

  const { values: servizioPerWebForm, changeHandler } = useForm(
    {
      ServizioPerWeb: " ",
    },
    []
  );

  useEffect(() => {
    getServizibyRitiri();
  }, []);

  useEffect(() => {
    setMainForm({
      ...mainForm,
      ServizioPerWeb: servizioPerWebForm.ServizioPerWeb,
    });
  }, [servizioPerWebForm]);

  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.error.light + "25" }}
    >
      <CardHeader
        title={"Servizio"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<TravelExploreTwoTone />}
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Servizio</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                size="small"
                value={servizioPerWebForm.ServizioPerWeb || ""}
                label="Servizio"
                onChange={(e) =>
                  changeHandler("ServizioPerWeb", e.target.value)
                }
              >
                <MenuItem value={" "}>Scegli il servizio</MenuItem>
                {servizi.map((servizio, i) => (
                  <MenuItem key={i} value={servizio.codice}>
                    {servizio.descrizione}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Servizio;
