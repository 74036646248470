import { Player } from "@lottiefiles/react-lottie-player";
import { ArrowBack } from "@mui/icons-material";
import { Button, Grid} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  let navigate = useNavigate();
  const { t } = useTranslation("common",{ keyPrefix: 'utilities' })

  return (
      <Grid item container direction={"row"} justifyContent={"center"} spacing={2}>
        <Grid xs={12} md={12} lg={12}>
          <Player
            src="https://assets1.lottiefiles.com/packages/lf20_suhe7qtm.json"
            loop
            autoplay
            speed={2}
            style={{height:"40%", width:"40%"}}
          />
        </Grid>
        <Button variant="contained" color={"primary"} onClick={()=> navigate(-1)} startIcon={<ArrowBack></ArrowBack>}>
        {t("ComeBack")}
        </Button>
      </Grid>
  );
};

export default NotFound;
