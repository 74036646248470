import createDataContext from "./createContext";
import { WebTrakingService } from "../api";

const INDIRIZZI_COD_TOTALI = "indirizzi_cod_totali";
const SPEDIZIONI_MESE_COR = "spedizioni_mese_cor";
const SPEDIZIONI_MESE_PR = "spedizioni_mese_pr";

const TRACKING_MESE_COR = "tracking_mese_cor";
const TRACKING_MESE_PR = "tracking_mese_pr";

const ORDINI_MESE_COR = "ordini_mese_cor";
const ORDINI_MESE_PR = "ordini_mese_pr";

const DOCUMENT_DASH_LAST = "document_dash_last";
const DOCUMENT_DASH_TOT = "document_dash_tot";


const INITIAL_STATE = {
  ordiniMesePrecendete: [
    { in_fase_ritiro: {}, ritirati: {} },
  ],
  ordiniMeseCorrente: [
    { in_fase_ritiro: {}, ritirati: {} },
  ],
  trackingMeseCorrente: [],
  trackingMesePrecendete: [],
  spedizioniMeseCorrente: [
    { in_consegna: {}, consegnate: {}, giacente: {}, in_transito: {} },
  ],
  spedizioniMesePrecendete: [
    { in_consegna: {}, consegnate: {}, giacente: {}, in_transito: {} },
  ],
  totaleIndirizziCodificati: [],
  lastDoc: [],
  totDoc: [],
  loadSpedizioni: false,
  loadIndirizziCod: false,
  loadRitiri: false,
  loadDocumentiDash: false,
};

const dashboardReducer = (state, action) => {
  switch (action.type) {
    case SPEDIZIONI_MESE_COR: {
      return {
        ...state,
        spedizioniMeseCorrente: action.payload,
        loadSpedizioni: true,
      };
    }
    case SPEDIZIONI_MESE_PR: {
      return { ...state, spedizioniMesePrecendete: action.payload };
    }
    case TRACKING_MESE_COR: {
      return {
        ...state,
        trackingMeseCorrente: action.payload,
        
      };
    }
    case TRACKING_MESE_PR: {
      return { ...state, trackingMesePrecendete: action.payload };
    }
    case ORDINI_MESE_COR: {
      return { ...state, ordiniMeseCorrente: action.payload, loadRitiri: true };
    }
    case ORDINI_MESE_PR: {
      return { ...state, ordiniMesePrecendete: action.payload };
    }
    case INDIRIZZI_COD_TOTALI: {
      return {
        ...state,
        totaleIndirizziCodificati: action.payload,
        loadIndirizziCod: true,
      };
    }
    case DOCUMENT_DASH_LAST: {
      return { ...state, lastDoc: action.payload };
    }
    case DOCUMENT_DASH_TOT: {
      return { ...state, totDoc: action.payload, loadDocumentiDash: true };
    }
    default: {
      return state;
    }
  }
};

const getTrackingMese = (dispatch) => async () => {
  const currmonth = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/tracking/dashboard/count?daterange=${"currmonth"}`
  );
  const prevmonth = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/tracking/dashboard/count?daterange=${"prevmonth"}`
  );
  if (prevmonth.data) {
    dispatch({ type: TRACKING_MESE_PR, payload: prevmonth.data });
  }
  if (currmonth.data) {
    dispatch({ type: TRACKING_MESE_COR, payload: currmonth.data });
  }
};

const getSpedizioniMese = (dispatch) => async () => {

  const currmonthINS = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/dashboard/count?daterange=${"currmonth"}&statoweb=INC`
  );
  const currmonthCON = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/dashboard/count?daterange=${"currmonth"}&statoweb=CON`
  );
  const currmonthGIA = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/dashboard/count?daterange=${"currmonth"}&statoweb=GIA`
  );
  const currmonthTRAN = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/dashboard/count?daterange=${"currmonth"}&statoweb=TRAN`
  );

  const prevmonthINS = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/dashboard/count?daterange=${"prevmonth"}&statoweb=INC`
  );
  const prevmonthCON = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/dashboard/count?daterange=${"prevmonth"}&statoweb=CON`
  );
  const prevmonthGIA = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/dashboard/count?daterange=${"prevmonth"}&statoweb=GIA`
  );
  const prevmonthTRAN = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/spedizioni/dashboard/count?daterange=${"prevmonth"}&statoweb=TRAN`
  );
  if(prevmonthINS.data && prevmonthCON.data && prevmonthGIA.data && prevmonthTRAN.data){
    dispatch({
      type: SPEDIZIONI_MESE_PR,
      payload: {
        in_consegna: prevmonthINS.data,
        consegnate: prevmonthCON.data,
        giacente: prevmonthGIA.data,
        in_transito: prevmonthTRAN.data,
      },
    });
  if(currmonthINS.data && currmonthCON.data && currmonthGIA.data && currmonthTRAN.data){
    dispatch({
      type: SPEDIZIONI_MESE_COR,
      payload: {
        in_consegna: currmonthINS.data,
        consegnate: currmonthCON.data,
        giacente: currmonthGIA.data,
        in_transito: currmonthTRAN.data,
      },
    });
  }
  
  }
};

const getOrdiniMese = (dispatch) => async (queryParam) => {
  const currmonthRIT = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/ordini/dashboard/count?daterange=${"currmonth"}&statoweb=RIT`
  );
  const currmonthINR = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/ordini/dashboard/count?daterange=${"currmonth"}&statoweb=INR`
  );

  const prevmonthRIT = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/ordini/dashboard/count?daterange=${"prevmonth"}&statoweb=RIT`
  );
  const prevmonthINR = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/ordini/dashboard/count?daterange=${"prevmonth"}&statoweb=INR`
  );
  
  if(prevmonthRIT.data && prevmonthINR.data) {
    dispatch({ type: ORDINI_MESE_PR, payload: { in_fase_ritiro:prevmonthINR.data , ritirati: prevmonthRIT.data }, });
  }
  if (currmonthINR.data && currmonthRIT.data) {
    dispatch({ type: ORDINI_MESE_COR, payload: { in_fase_ritiro:currmonthINR.data , ritirati: currmonthRIT.data }, });
  }
};

const getindirizziCodificatiTotale = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/indirizzicodificati/dashboard/count`
  );
  if (response.data) {
    dispatch({ type: INDIRIZZI_COD_TOTALI, payload: response.data });
  }
};

const getDocumentiDash = (dispatch) => async () => {
  const lastdoc = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/docemessi/dashboard/lastdoc`
  );
  if (lastdoc.data) {
    dispatch({ type: DOCUMENT_DASH_LAST, payload: lastdoc.data });
  }
  const count = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/docemessi/dashboard/count`
  );
  if (count.data) {
    dispatch({ type: DOCUMENT_DASH_TOT, payload: count.data });
  }
};

export const { Provider, Context } = createDataContext(
  dashboardReducer,
  {
    getSpedizioniMese,
    getOrdiniMese,
    getindirizziCodificatiTotale,
    getDocumentiDash,
    getTrackingMese,
  }, // actions
  INITIAL_STATE // initial state
);
