import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import BasicSpeedDial from "./BasicSpeedDial";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  Cancel,
  FilterAltRounded,
  PlayArrow,
  TravelExploreTwoTone,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../hooks";
import { useEffect } from "react";

export default function FilterBar({
  type,
  recordCount,
  filter,
  aggiornaFiltri,
  getMethod,
  resetFiltri,
  setOpenTabella,
  setViewStatoWeb,
  setViewAdvFilter,
  setPageResetVar,
  listStatoWeb,
  setViewResa,
  listaResa
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [dataFrom, setdataFrom] = React.useState(null);
  const [dataTo, setdataTo] = React.useState(null);

  const [MittProvincia, setMittProvincia] = React.useState("");
  const [MittLocalita, setMittLocalita] = React.useState("");
  const [MittRagSoc, setMittRagSoc] = React.useState("");
  const [MittNazione, setMittNazione] = React.useState("");

  const [Stato, setStato] = React.useState(" ");

  const [searchall, setSearchall] = React.useState("");
  const [resa, setResa] = React.useState(" ");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const limit = 100;
  const offset = 0;

  const ChangeValueHandler = (key, value, setFunc) => {
    if (key === "all") {
      resetFiltri();
      setdataFrom("");
      setdataTo("");
      setMittProvincia("");
      setMittLocalita("");
      setMittRagSoc("");
      setMittNazione("");
      setStato("");
      setSearchall("");
      setResa("")
      setOpen(!open);
    } else {
      setFunc(value);
      aggiornaFiltri(key, value);
      /* setTimeout(() => , 0.1 * 1000); */
    }
  };

  useEffect(() => {
    setPageResetVar(false);
  }, []);

  useEffect(() => {
    if (filter?.searchall === "") {
      handleAnnullaRicerca()
    }
  }, [filter]);

  const navigate = useNavigate();

  const PressKey = (e) => {
    if (e === 13) {
      handleAvviaRicerca();
      //getMethod("", limit, offset, navigate, [], recordCount, false, filter);
    }
  };

  const handleAvviaRicerca = () => {
    getMethod("", limit, offset, navigate, [], recordCount, false, filter);
    setPageResetVar(true);
  };

  const handleAnnullaRicerca = () => {
    getMethod("", limit, offset, navigate, [], recordCount, false, filter)
    setPageResetVar(true);
  };

  let dimension = useWindowDimensions();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          border: 2,
          borderColor: (theme) => theme.palette.primary.light,
          borderRadius: 20,
          background: (theme) => theme.palette.background.paper,
          color: "black",
        }}
        elevation={0}
      >
        <Toolbar variant="dense">
          {setViewAdvFilter ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => setOpen(!open)}
            >
              <Slide in={true}>
                <FilterAltRounded color={"primary"} />
              </Slide>
            </IconButton>
          ) : (
            <></>
          )}

          <TextField
            hiddenLabel
            autoComplete="off"
            size="small"
            fullWidth
            value={searchall || ""}
            onChange={(e) =>
              ChangeValueHandler("searchall", e.target.value, setSearchall)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Player
                    src="https://assets8.lottiefiles.com/packages/lf20_nIuZ4T.json"
                    autoplay
                    style={{ height: 45, width: 45 }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {filter?.searchall !== "" ? <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => ChangeValueHandler("searchall", "", setSearchall)}>
                    <Cancel />
                  </IconButton> : <></>}
                </>
              )


            }}
            variant="standard"
            placeholder={"Cerca ..."}
            onKeyPress={(e) => PressKey(e.charCode)}
          />
          {dimension ? <></> : <BasicSpeedDial />}
        </Toolbar>
      </AppBar>
      <Stack direction={"row"} spacing={2}>
        {dimension ? <BasicSpeedDial /> : <></>}
      </Stack>
      <Box>
        {setViewAdvFilter ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={1}>
              <Card elevation={0}>
                <CardHeader
                  title={"Data"}
                  subheader={""}
                  avatar={<TravelExploreTwoTone />}
                />
                <CardContent>
                  <Stack direction={"row"} spacing={2}>
                    <DatePicker
                      label="da"
                      value={dataFrom || null}
                      onChange={(newValue) => {
                        ChangeValueHandler(
                          "DataIniziale",
                          newValue,
                          setdataFrom
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          size="small"
                          {...params}
                        />
                      )}
                    />
                    <DatePicker
                      label="a"
                      value={dataTo || null}
                      onChange={(newValue) => {
                        ChangeValueHandler("DataFinale", newValue, setdataTo);
                      }}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card elevation={0}>
                <CardHeader
                  title={type ? "Destinatario" : "Mittente"}
                  subheader={""}
                  avatar={<TravelExploreTwoTone />}
                />

                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        size="small"
                        label="Ragione sociale"
                        variant="outlined"
                        value={MittRagSoc || ""}
                        onChange={(e) => {
                          ChangeValueHandler(
                            type ? "DestRagSoc" : "MittRagSoc",
                            e.target.value,
                            setMittRagSoc
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Località"
                        variant="outlined"
                        autoComplete="off"
                        value={MittLocalita || ""}
                        onChange={(e) => {
                          ChangeValueHandler(
                            type ? "DestLocalita" : "MittLocalita",
                            e.target.value,
                            setMittLocalita
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        size="small"
                        label="Provincia"
                        variant="outlined"
                        value={MittProvincia || ""}
                        onChange={(e) => {
                          ChangeValueHandler(
                            type ? "DestProvincia" : "MittProvincia",
                            e.target.value,
                            setMittProvincia
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        size="small"
                        label="Nazione"
                        variant="outlined"
                        value={MittNazione || ""}
                        onChange={(e) => {
                          ChangeValueHandler(
                            type ? "DestNazione" : "MittNazione",
                            e.target.value,
                            setMittNazione
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card elevation={0}>
                <CardHeader
                  title={"Riferimento"}
                  subheader={""}
                  avatar={<TravelExploreTwoTone />}
                />
                <CardContent>
                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      sx={{ width: "100%" }}
                      autoComplete="off"
                      size="small"
                      label="Riferimento"
                      variant="outlined"
                      onChange={(e) =>
                        ChangeValueHandler(
                          "Riferimento",
                          e.target.value,
                          setStato
                        )
                      }
                    />
                  </Stack>
                </CardContent>
              </Card>

              {setViewStatoWeb ? (
                <Card elevation={0}>
                  <CardHeader
                    title={"Stato"}
                    subheader={""}
                    avatar={<TravelExploreTwoTone />}
                  />
                  <CardContent>
                    <Stack direction={"row"} spacing={2}>
                      <FormControl size="small" sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Stato
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={Stato || " "}
                          label="Stato"
                          onChange={(e) =>
                            ChangeValueHandler(
                              "StatoWeb",
                              e.target.value,
                              setStato
                            )
                          }
                        >
                          <MenuItem value={" "}>Seleziona stato ...</MenuItem>
                          {listStatoWeb.map((res, i) => (
                            <MenuItem value={res.value} key={i}>{res.text}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </CardContent>
                </Card>
              ) : null}

              {setViewResa ? (
                <Card elevation={0}>
                  <CardHeader
                    title={"Resa"}
                    subheader={""}
                    avatar={<TravelExploreTwoTone />}
                  />
                  <CardContent>
                    <Stack direction={"row"} spacing={2}>
                      <FormControl size="small" sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Resa
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={resa || " "}
                          label="Resa"
                          onChange={(e) =>
                            ChangeValueHandler(
                              "resa",
                              e.target.value,
                              setResa
                            )
                          }
                        >
                          <MenuItem value={" "}>Seleziona resa ...</MenuItem>
                          {listaResa.map((res, i) => (
                            <MenuItem value={res.value} key={i}>{res.text}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </CardContent>
                </Card>
              ) : null}
              <Stack direction={"row"} spacing={2} mt={1}>
                <Button
                  variant="contained"
                  size="small"
                  color={"primary"}
                  endIcon={<PlayArrow />}
                  onClick={() => handleAvviaRicerca()}
                >
                  Avvia ricerca
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color={"warning"}
                  endIcon={<Cancel />}
                  onClick={() => ChangeValueHandler("all")}
                >
                  Resetta filtri
                </Button>
              </Stack>
            </Box>
            {/* <TabContext value={value}>
              <Box mt={1} ml={-2}>
                <Tabs
                  value={value}
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  <Tab label="Data" value="1" />
                  <Tab label={type ? "Destinatario" : "Mittente"} value="2" />
                  {setViewStatoWeb ? (
                    <Tab label="Stato" value="3" index={3} />
                  ) : null}
                  <Tab label="Riferimento" value="4" />
                </Tabs>
              </Box>
              <TabPanel value="1">
                <Stack direction={"row"} spacing={2} ml={-3}>
                  <DatePicker
                    label="da"
                    value={dataFrom || null}
                    onChange={(newValue) => {
                      ChangeValueHandler("DataIniziale", newValue, setdataFrom);
                    }}
                    renderInput={(params) => (
                      <TextField autoComplete="off" size="small" {...params} />
                    )}
                  />
                  <DatePicker
                    label="a"
                    value={dataTo || null}
                    onChange={(newValue) => {
                      ChangeValueHandler("DataFinale", newValue, setdataTo);
                    }}
                    renderInput={(params) => (
                      <TextField autoComplete="off" size="small" {...params} />
                    )}
                  />
                </Stack>
              </TabPanel>
              <TabPanel value="2">
                <Grid container ml={-4} spacing={1}>
                  <Grid item xs={12} md={3} lg={3}>
                    <TextField
                      autoComplete="off"
                      size="small"
                      label="Ragione sociale"
                      variant="outlined"
                      value={MittRagSoc || ""}
                      onChange={(e) => {
                        ChangeValueHandler(
                          type ? "DestRagSoc" : "MittRagSoc",
                          e.target.value,
                          setMittRagSoc
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <TextField
                      size="small"
                      label="Località"
                      variant="outlined"
                      autoComplete="off"
                      value={MittLocalita || ""}
                      onChange={(e) => {
                        ChangeValueHandler(
                          type ? "DestLocalita" : "MittLocalita",
                          e.target.value,
                          setMittLocalita
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <TextField
                      autoComplete="off"
                      size="small"
                      label="Provincia"
                      variant="outlined"
                      value={MittProvincia || ""}
                      onChange={(e) => {
                        ChangeValueHandler(
                          type ? "DestProvincia" : "MittProvincia",
                          e.target.value,
                          setMittProvincia
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <TextField
                      autoComplete="off"
                      size="small"
                      label="Nazione"
                      variant="outlined"
                      value={MittNazione || ""}
                      onChange={(e) => {
                        ChangeValueHandler(
                          type ? "DestNazione" : "MittNazione",
                          e.target.value,
                          setMittNazione
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              {setViewStatoWeb ? (
                <TabPanel value="3">
                  <Stack direction={"row"} spacing={2} ml={-3}>
                    <FormControl size="small" sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Stato
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Stato || " "}
                        label="Stato"
                        onChange={(e) =>
                          ChangeValueHandler(
                            "StatoWeb",
                            e.target.value,
                            setStato
                          )
                        }
                      >
                        <MenuItem value={" "}>Seleziona stato ...</MenuItem>
                        <MenuItem value={"INS"}>Inserito</MenuItem>
                        <MenuItem value={"LAV"}>In lavorazione</MenuItem>
                        <MenuItem value={"ASS"}>Ritiro assegnato</MenuItem>
                        <MenuItem value={"OK"}>Ritiro eseguito</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </TabPanel>
              ) : null}

              <TabPanel value="4">
                <Stack direction={"row"} spacing={2} ml={-3}>
                  <TextField
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    size="small"
                    label="Riferimento"
                    variant="outlined"
                    onChange={(e) =>
                      ChangeValueHandler(
                        "Riferimento",
                        e.target.value,
                        setStato
                      )
                    }
                  />
                </Stack>
              </TabPanel>
              <Stack direction={"row"} spacing={2} mt={1}>
                <Button
                  variant="contained"
                  size="small"
                  color={"primary"}
                  endIcon={<PlayArrow />}
                  onClick={() => handleAvviaRicerca()}
                >
                  Avvia ricerca
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color={"warning"}
                  endIcon={<Cancel />}
                  onClick={() => ChangeValueHandler("all")}
                >
                  Resetta filtri
                </Button>
              </Stack>
            </TabContext> */}
            {/* <VerticalTabs/> */}
          </Collapse>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
