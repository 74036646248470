import React, {useEffect, useContext, useState} from 'react'
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { DocumentiContext } from '../context';
import { Grid, Stack, LinearProgress } from '@mui/material';
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useNavigate } from 'react-router-dom';



const limit = 100;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentiDialog = ({isDialogOpen, setIsDialogOpen, selectedGroup, setSelectedGroup}) => {

    const [pageResetVar, setPageResetVar] = useState(true)
    const [checkBoxSelectionPrint, setCheckBoxSelectionPrint] = useState([]);

    const {
        state:{documentsByGroupList, filter, columnsByGroup, countRecordByGroup},
        aggiornaFiltri,
        getDocumentsByGroup,
        resetFiltri,
        clearGroupData,
      } = useContext(DocumentiContext);


    useEffect(()=>{
        getDocumentsByGroup(selectedGroup)
    }, [])

  const navigate = useNavigate()

  const handleClose = () => {
    setIsDialogOpen(false);
    setSelectedGroup("");
    clearGroupData();
  };


  return (
    <div>
        <Dialog
            fullScreen
            open={isDialogOpen}
            //onClose={() =>handleClose()}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {handleClose()}}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Documenti - {selectedGroup}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={2} sx={{px:5, py:5, minWidth:"100%"}}>
                <Grid  item mr={2} spacing={2} xs={12} md={12} lg={12}>
                    {
                        columnsByGroup.length > 0?
                        (
                            <CustomPaginationActionsTable
                                setCheckBoxSelectionPrint={setCheckBoxSelectionPrint}
                                rows={documentsByGroupList}
                                columns={columnsByGroup}
                                limit={limit}
                                recordCount={countRecordByGroup}
                                idElement={"IDDocumento"}
                                actionShow={true}
                                routeDetail={"/documenti"}
                                resetFiltri={resetFiltri}
                                pageReset={pageResetVar}
                                viewAction={{
                                    mail: false,
                                    view: false,
                                    location: false,
                                    download: true,
                                    print: false,
                                }}
                            />
                        )
                        :
                        (
                            
                            <Stack justifyContent={"center"} alignContent="center">
                                <Typography>Caricamento...</Typography>
                                <LinearProgress color="success" />
                            </Stack>
                             
                        )
                    }
                </Grid>
            </Grid>
        </Dialog>
    </div>
  )
}

export default DocumentiDialog