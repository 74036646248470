import createDataContext from "./createContext";
import { WebTrakingService } from "../api";
import { sendNotification } from "../hooks/useNotification";
import { NotificationContent } from "../components";
import moment from "moment";

const ORDER_LIST = "order_list";
const COLUMNS_CUSTOM = "columns_custom";
const ORDER_DETAIL = "order_detail";
const STEPPER = "stepper";
const SERVIZI = "servizi";
const INDICATORI_MITT = "indicatori_mitt";
const INDICATORI_DEST = "indicatori_dest";
const ORDINE_DA_INVIARE = "ordine_da_inviare";
const CONFIG_ORDER = "config_order";
const TIPI_INCASSO = "tipi_incasso";
const VALUTE = "valute";
const ADR_CLASSI = "adr_classi";
const ADR_IMBALLAGGI = "adr_imballaggi";
const ERROR_MESSAGGE = "error_messagge";
const FILTER = "filter";
const FILTER_RESET = "filter_reset";
const COLUMNS_DETAIL = "columns_detail";

const INITIAL_STATE = {
  orderList: [],
  columnsCustom: [],
  columnsCustomDetail: [],
  stepper: [],
  orderDetail: [
    {
      data: [],
      columns: [],
      displaytext: "",
    },
  ],
  recordCount: 0,
  servizi: [],
  indicatoriMitt: [],
  indicatoriDest: [],
  ordineDaInviare: [],
  configOrder: [],
  tipiIncassi: [],
  valuteList: [],
  adrImballaggi: [],
  adrClassi: [],
  errorMessage: "",
  filter: {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    MittProvincia: "",
    MittLocalita: "",
    MittRagSoc: "",
    MittNazione: "",
    StatoWeb: "",
    resa:""
  },
  azioni:[]
};

const orderWithdrawReducer = (state, action) => {
  switch (action.type) {
    case ORDER_LIST: {
      const { data, countRecord, actionOrder } = action.payload;
      return { ...state, orderList: data, recordCount: countRecord, azioni:actionOrder };
    }
    case STEPPER: {
      return { ...state, stepper: action.payload };
    }
    case COLUMNS_CUSTOM: {
      return { ...state, columnsCustom: action.payload };
    }
    case SERVIZI: {
      return { ...state, servizi: action.payload };
    }
    case INDICATORI_MITT: {
      return { ...state, indicatoriMitt: action.payload };
    }
    case INDICATORI_DEST: {
      return { ...state, indicatoriDest: action.payload };
    }
    case ORDER_DETAIL: {
      return { ...state, orderDetail: action.payload };
    }
    case ORDINE_DA_INVIARE: {
      return { ...state, ordineDaInviare: action.payload };
    }
    case CONFIG_ORDER: {
      return { ...state, configOrder: action.payload };
    }
    case TIPI_INCASSO: {
      return { ...state, tipiIncassi: action.payload };
    }
    case VALUTE: {
      return { ...state, valuteList: action.payload };
    }
    case ADR_CLASSI: {
      return { ...state, adrClassi: action.payload };
    }
    case ADR_IMBALLAGGI: {
      return { ...state, adrImballaggi: action.payload };
    }
    case ERROR_MESSAGGE: {
      return { ...state, errorMessage: action.payload };
    }
    case FILTER: {
      const { key, value } = action.payload;
      return { ...state, filter: { ...state.filter, [key]: value } };
    }
    case FILTER_RESET: {
      return { ...state, filter: INITIAL_STATE.filter };
    }
    case COLUMNS_DETAIL: {
      return { ...state, columnsCustomDetail: action.payload };
    }
    default: {
      return state;
    }
  }
};

const popolaArrayOrdine = (dispatch) => async (payload) => {
  dispatch({ type: ORDINE_DA_INVIARE, payload: payload });
};

const getOrderList =
  (dispatch) =>
  async (
    rif,
    limit,
    offset,
    navigate,
    dataSet,
    recordCount,
    reset,
    filterTracking
  ) => {
    if (dataSet.length > recordCount) {
      /*  non fare nulla */
    } else {
      const response = await WebTrakingService.get(
        `${
          JSON.parse(localStorage.getItem("configurazioni")).server
        }/ordini/websearch?limit=${limit}&offset=${offset}&riferimento=${
          filterTracking ? filterTracking.Riferimento : ""
        }&searchall=${
          filterTracking ? filterTracking.searchall : ""
        }&datainiziale=${
          filterTracking === undefined || filterTracking?.DataIniziale === ""
            ? ""
            : moment(filterTracking?.DataIniziale).format("YYYY-MM-DD")
        }&datafinale=${
          filterTracking === undefined || filterTracking?.DataFinale === ""
            ? ""
            : moment(filterTracking?.DataFinale).format("YYYY-MM-DD")
        }&mittprovincia=${
          filterTracking ? filterTracking.MittProvincia : ""
        }&mittlocalita=${
          filterTracking ? filterTracking.MittLocalita : ""
        }&mittragsoc=${
          filterTracking ? filterTracking.MittRagSoc : ""
        }&mittnazione=${
          filterTracking ? filterTracking.MittNazione : ""
        }&statoweb=${filterTracking ? filterTracking.StatoWeb : ""}&resa=${filterTracking ? filterTracking.resa : ""}`
      );
      if (response.data) {
        let data = dataSet;
        if (dataSet.length === 0 || rif !== "") {
          dispatch({
            type: ORDER_LIST,
            payload: {
              data: response.data.data,
              countRecord: response.data.recordcount_total,
              actionOrder:response.data.azioni,
            },
          });
        } else {
          response.data.data.forEach((element) => {
            data.push(element);
          });
          dispatch({
            type: ORDER_LIST,
            payload: {
              data: data,
              countRecord: response.data.recordcount_total,
              actionOrder:response.data.azioni,
            },
          });
        }
      }
      let col = [
        /*   {
          field: "checkbox",
          headerName: "",
          //flex: 1,
        }, */
        {
          field: "action",
          headerName: "Azioni",
          //flex: 1,
        },
      ];
      response.data.columns.forEach((data) => {
        col = [
          ...col,
          {
            field: data.id,
            headerName: data.label,
            minWidth: data.minWidth,
            flex: 1,
          },
        ];
      });
      //dispatch({ type: STEPPER, payload: response.data.displaystatus });
      dispatch({ type: COLUMNS_CUSTOM, payload: col });
    }
  };

const getServizibyRitiri = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/servizi`
  );
  if (response) {
    //console.log(response.data.data)
    dispatch({ type: SERVIZI, payload: response.data.data });
  }
};

const getIndicatoriMitt = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/indicatorioperativi?mitdes=mit`
  );
  if (response) {
    dispatch({ type: INDICATORI_MITT, payload: response.data.data });
  }
};

const getIndicatoriDest = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/indicatorioperativi?mitdes=des`
  );
  if (response) {
    dispatch({ type: INDICATORI_DEST, payload: response.data.data });
  }
};

const getConfigModeOrder = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/ordini/config`
  );
  if (response) {
    dispatch({ type: CONFIG_ORDER, payload: response.data.data });
  }
};

const getTipiIncassi = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/tipiincasso`
  );
  if (response) {
    dispatch({ type: TIPI_INCASSO, payload: response.data.data });
  }
};

const getValute = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/valute`
  );
  if (response) {
    dispatch({ type: VALUTE, payload: response.data.data });
  }
};

const getClassi = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${JSON.parse(localStorage.getItem("configurazioni")).server}/adr/classi`
  );
  if (response) {
    dispatch({ type: ADR_CLASSI, payload: response.data.data });
  }
};

const getImballaggi = (dispatch) => async () => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/adr/imballaggi`
  );
  if (response) {
    dispatch({ type: ADR_IMBALLAGGI, payload: response.data.data });
  }
};

const postOrdine =
  (dispatch) => async (ordine, handleCloseDialog, navigate) => {
    const response = await WebTrakingService.post(
      `${JSON.parse(localStorage.getItem("configurazioni")).server}/ordini`,
      ordine
    );
    if (response) {
      if (response.data.Success) {
        handleCloseDialog();
        dispatch({ type: ERROR_MESSAGGE, payload: "" });
        sendNotification(
          <NotificationContent service={"Ordine di ritiro!"} />,
          "Ordine di ritiro creato con successo",
          "success",
          5
        );
        getOrderList(dispatch)("", 100, 0, navigate, [], 0, false, {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          MittProvincia: "",
          MittLocalita: "",
          MittRagSoc: "",
          MittNazione: "",
          StatoWeb: "",
          resa:""
        });
      } else {
        sendNotification(
          <NotificationContent service={"Errore!"} />,
          response.data.Err_Message,
          "error",
          5
        );
        dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
      }
    }
  };

const errorCancel = (dispatch) => async () => {
  dispatch({ type: ERROR_MESSAGGE, payload: "" });
};

const getRitiro = (dispatch) => async (prgOrdine) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/ordini/viewtracking/${prgOrdine}`
  );
  if (response.data) {
    let col = [];
    let filter = response.data.data.filter(
      (res) => res.Lat !== "" && res.Lon !== ""
    );
    if (filter.length > 0) {
      col = [
       /*  {
          field: "action",
          headerName: "Azioni",
          //flex: 1,
        }, */
      ];
    }
    response.data.columns.forEach((data) => {
      if (data.id !== "Lat" && data.id !== "Lon") {
        col = [
          ...col,
          {
            field: data.id,
            headerName: data.label,
            minWidth: data.minWidth,
          },
        ];
      }
    });
    dispatch({ type: STEPPER, payload: response.data.displaystatus });
    dispatch({ type: COLUMNS_DETAIL, payload: col });
    dispatch({ type: ORDER_DETAIL, payload: response.data });
  }
};

const getEditRitiro = (dispatch) => async (prgOrdine) => {
  dispatch({ type: ORDER_DETAIL, payload: [] });
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/ordini/${prgOrdine}?action=edit`
    );
  if (response.data) {
    dispatch({ type: ORDER_DETAIL, payload: response.data });
  }
};

const putOrdine =
  (dispatch) => async (ordine, handleCloseDialog, navigate) => {
    const response = await WebTrakingService.put(
      `${JSON.parse(localStorage.getItem("configurazioni")).server}/ordini/ordine/${ordine.PrgOrdine}`,
      ordine
    );
    if (response) {
      if (response.data.Success) {
        handleCloseDialog();
        dispatch({ type: ERROR_MESSAGGE, payload: "" });
        sendNotification(
          <NotificationContent service={"Modifica Ordine di ritiro!"} />,
          "Ordine di ritiro modificato con successo",
          "success",
          5
        );
        getOrderList(dispatch)("", 100, 0, navigate, [], 0, false, {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          MittProvincia: "",
          MittLocalita: "",
          MittRagSoc: "",
          MittNazione: "",
          StatoWeb: "",
          resa:""
        });
      } else {
        sendNotification(
          <NotificationContent service={"Errore!"} />,
          response.data.Err_Message,
          "error",
          5
        );
        dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
      }
    }
  };

  const delOrdine =
  (dispatch) => async (PrgOrdine,navigate) => {
    const response = await WebTrakingService.delete(
      `${JSON.parse(localStorage.getItem("configurazioni")).server}/ordini/ordine/${PrgOrdine}`);
    if (response) {
      if (response.data.Success) {
        dispatch({ type: ERROR_MESSAGGE, payload: "" });
        sendNotification(
          <NotificationContent service={"Ritiro eliminato!"} />,
          "Ordine di ritiro eliminato con successo",
          "success",
          5
        );
        getOrderList(dispatch)("", 100, 0, navigate, [], 0, false, {
          searchall: "",
          DataIniziale: "",
          DataFinale: "",
          Riferimento: "",
          MittProvincia: "",
          MittLocalita: "",
          MittRagSoc: "",
          MittNazione: "",
          StatoWeb: "",
          resa:""
        });
      } else {
        sendNotification(
          <NotificationContent service={"Errore!"} />,
          response.data.Err_Message,
          "error",
          5
        );
        dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
      }
    }
  };


const aggiornaFiltri = (dispatch) => async (key, value) => {
  dispatch({ type: FILTER, payload: { key: key, value: value } });
};

const resetFiltri = (dispatch) => async () => {
  dispatch({ type: FILTER_RESET });
  getOrderList(dispatch)("", 100, 0, false, [], 0, false, {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    MittProvincia: "",
    MittLocalita: "",
    MittRagSoc: "",
    MittNazione: "",
    StatoWeb: "",
    resa:""
  });
};

const pulisciOrderDetail =(dispatch)=>async()=>{
  dispatch({ type: ORDER_DETAIL, payload:[]});
}

const ritiriEmail = (dispatch) => async (prgOrdine) => {
  dispatch({ type: ORDER_DETAIL, payload: [] });
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/ordini/mailto/${prgOrdine}?type=info`
    );
  if (response.data) {
    window.location = response.data.mailto
  }
};

export const { Provider, Context } = createDataContext(
  orderWithdrawReducer,
  {
    getOrderList,
    getServizibyRitiri,
    getIndicatoriDest,
    getIndicatoriMitt,
    popolaArrayOrdine,
    getConfigModeOrder,
    getTipiIncassi,
    getValute,
    getClassi,
    getImballaggi,
    postOrdine,
    errorCancel,
    getRitiro,
    aggiornaFiltri,
    resetFiltri,
    getEditRitiro,
    putOrdine,
    pulisciOrderDetail,
    delOrdine,
    ritiriEmail
  }, // actions
  INITIAL_STATE // initial state
);
