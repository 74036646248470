import * as React from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { NationsContext, OrdiniRitiroContext } from "../context";
import { useEffect } from "react";
import { AddBoxTwoTone } from "@mui/icons-material";
import {
  AddOrdiniRitiriNew,
  DialogAlertDelete,
  DialogFullScreen,
  FilterBar,
} from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const listaStato = [
  { value: "INS", text: "Inserito" },
  { value: "PRC", text: "Preso in carico" },
  { value: "ASS1", text: "In gestione" },
  { value: "RIT", text: "Eseguito" },
];

const listaResa = [
  { value: "PF", text: "Porto franco" },
  { value: "PA", text: "Assegnato" },
  { value: "TR", text: "Triangolazione" },
];

const Ritiri = () => {
  const { t } = useTranslation("common", { keyPrefix: "ritiri" });
  const [pageResetVar, setPageResetVar] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const {
    state: {
      orderList,
      columnsCustom,
      recordCount,
      filter,
      azioni,
    },
    getOrderList,
    getConfigModeOrder,
    aggiornaFiltri,
    resetFiltri,
    delOrdine,
    ritiriEmail,
  } = useContext(OrdiniRitiroContext);

  const { getNationsListsData } = useContext(NationsContext);

  const navigate = useNavigate();

  useEffect(() => {
    titlePage("", "Ordini di ritiro");
    getOrderList("", limit, offset, navigate, [], recordCount, false, filter);
    getConfigModeOrder();
    getNationsListsData();
  }, []);



  const deleteAction = (e) => {
    setOpenAlertDelete(true);
    setIdDelete(e);
  };

  const emailActionRitiri = (e) => {
    ritiriEmail(e);
  };


  return (
    <Grid container spacing={2}>
      <Grid container item mr={2} spacing={2} direction={"row"}>
        <Grid item xs={12} md={12} lg={12}>
          <FilterBar
            type={false}
            recordCount={recordCount}
            filter={filter}
            aggiornaFiltri={aggiornaFiltri}
            getMethod={getOrderList}
            resetFiltri={resetFiltri}
            setViewStatoWeb={true}
            setViewAdvFilter={true}
            setPageResetVar={setPageResetVar}
            listStatoWeb={listaStato}
            setViewResa={true}
            listaResa={listaResa}
          ></FilterBar>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <ButtonGroup size={"large"} mb={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("/ritiri/new")}
            startIcon={<AddBoxTwoTone />}
          >
            Crea ritiro
          </Button>
        </ButtonGroup>
        {columnsCustom.length > 0 ? (
          <CustomPaginationActionsTable
            rows={orderList}
            columns={columnsCustom}
            limit={limit}
            navigate={navigate}
            getMethodForList={getOrderList}
            recordCount={recordCount}
            idElement={"PrgOrdine"}
            routeDetail={"/ritiri"}
            actionShow={true}
            resetFiltri={resetFiltri}
            pageReset={pageResetVar}
            viewAction={{
              mail: azioni?.email?.visible,
              view: true,
              location: false,
              print: false,
              edit: azioni?.modifica?.visible,
              delete: azioni?.annulla?.visible,
            }}
            deleteAction={(e) => deleteAction(e)}
            emailAction={(e) => emailActionRitiri(e)}
            filterList={filter}
          />
        ) : (
          <Stack justifyContent={"center"} alignContent="center">
            <Typography>{t("loadPage")}</Typography>
            <LinearProgress color="success" />
          </Stack>
        )}
      </Grid>
      <DialogAlertDelete
        open={openAlertDelete}
        setOpen={setOpenAlertDelete}
        idDelete={idDelete}
        delFunc={delOrdine}
        setIdDelete={setIdDelete}
        title={"ritiro"}
      />
    </Grid>
  );
};

export default Ritiri;
