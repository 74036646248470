import React, {useEffect, useState, useContext} from 'react'
import { Add, Delete, ListTwoTone } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {useForm} from "../../hooks"

const DettaglioMisureMerce = ({prevMainForm, setPrevMainForm}) => {

    const [lastChoice, setLastChoice] = useState("SEL");


    const [rows, setRows] = useState([
        {
          id: uuidv4(),
          Tipo: "SEL",
          Quantita: 0,
          Peso: 0,
          Lunghezza: 0,
          Larghezza: 0,
          Altezza: 0,
        },
      ]);

      const [totRows, setTotRows] = useState({
        Quantita: 0,
        Peso: "",
        Volume: 0,
      });
    
      const { values: dettaglioDimensioneMerceForm, changeHandler } = useForm(
        {
          ordmerce: [],
        },
        []
      );

      useEffect(() => {
        if (dettaglioDimensioneMerceForm.ordmerce.length > 0) {
          setRows(dettaglioDimensioneMerceForm.ordmerce);
        }
      }, []);

      useEffect(() => {
        setPrevMainForm({
          ...prevMainForm,
          ordmerce: dettaglioDimensioneMerceForm.ordmerce,
        });
      }, [dettaglioDimensioneMerceForm]);

      useEffect(() => {
        const QuantitaTot = rows.reduce((acc, obj) => {
          let parser = parseInt(obj.Quantita);
          if (isNaN(acc + parser)) {
            return 0;
          }
          return acc + parser;
        }, 0);
        const PesoTot = rows.reduce((acc, obj) => {
          let parser = parseInt(obj.Peso);
          if (isNaN(acc + parser)) {
            return 0;
          }
          return acc + parser;
        }, 0);
    
        let VolumeTot = rows.reduce((acc, obj) => {
          let quantita = parseInt(obj.Quantita);
          let larghezza = parseInt(obj.Larghezza);
          let lunghezza = parseInt(obj.Lunghezza);
          let altezza = parseInt(obj.Altezza);
          if (isNaN((quantita * larghezza * lunghezza * altezza) / 1000000)) {
            return 0;
          }
          return acc + (quantita * larghezza * lunghezza * altezza) / 1000000;
        }, 0);
    
        setTotRows({
          Quantita: QuantitaTot,
          Peso: PesoTot,
          Volume: VolumeTot.toFixed(2),
        });
      }, [rows]);
    

      const cancellaInserimento = (id) => {
        setRows([...rows.filter((res) => res.id !== id)]);
        changeHandler("ordmerce", [...rows.filter((res) => res.id !== id)]);
      };
    
      const handleChangeValue = (key, value, index, row) => {
        let arrayData = [...rows.filter((res) => res.id !== row.id)];
        arrayData.splice(index, 0, { ...rows[index], [key]: value });
        setRows(arrayData);
        changeHandler("ordmerce", arrayData);
      };

      const addDettaglioOrdine = () => {
        setRows([
          ...rows,
          {
            id: uuidv4(),
            Tipo: lastChoice,
            Quantita: 0,
            Peso: 0,
            Lunghezza: 0,
            Larghezza: 0,
            Altezza: 0,
          },
        ]);
      };
  return (
<Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.error.light + "25" }}
    >
      <CardHeader
        title={"Dettaglio merce"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<ListTwoTone />}
      />
      <CardContent>
        {rows.map((res, i) => {
          return (
            <Grid item container spacing={1} key={i}>
              <Grid item xs={12} md={1} lg={1} mr={-5} mt={2}>
                <IconButton
                  onClick={() => cancellaInserimento(res.id)}
                  color="error"
                >
                  <Delete />
                </IconButton>
              </Grid>
              <Grid item xs={12} md={2} lg={2} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-from-nations">Tipo</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-from-nations"
                    id="select-from-nations"
                    value={res.Tipo || ""}
                    onChange={(e) => {
                      handleChangeValue("Tipo", e.target.value, i, res);
                      setLastChoice(e.target.value);
                    }}
                    label="Tipo"
                  >
                    <MenuItem value={"SEL"}>Seleziona</MenuItem>
                    <MenuItem value={"C"}>Colli</MenuItem>
                    <MenuItem value={"P"}>Bancali</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={1} lg={1}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Quantità"
                  autoComplete="off"
                  value={res.Quantita || ""}
                  onChange={(e) =>
                    handleChangeValue("Quantita", e.target.value, i, res)
                  }
                />
              </Grid>

              <Grid item xs={12} md={2} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Peso (kg)"
                  autoComplete="off"
                  value={res.Peso || ""}
                  onChange={(e) =>
                    handleChangeValue("Peso", e.target.value, i, res)
                  }
                />
              </Grid>

              <Grid item xs={12} md={2} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Lunghezza (cm)"
                  autoComplete="off"
                  value={res.Lunghezza || ""}
                  onChange={(e) =>
                    handleChangeValue("Lunghezza", e.target.value, i, res)
                  }
                />
              </Grid>

              <Grid item xs={12} md={2} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Larghezza (cm)"
                  autoComplete="off"
                  value={res.Larghezza || ""}
                  onChange={(e) =>
                    handleChangeValue("Larghezza", e.target.value, i, res)
                  }
                />
              </Grid>

              <Grid item xs={12} md={2} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Altezza (cm)"
                  autoComplete="off"
                  value={res.Altezza || ""}
                  onChange={(e) =>
                    handleChangeValue("Altezza", e.target.value, i, res)
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </CardContent>
      <CardActions sx={{ justifyContent: "right", direction: "row" }}>
        <Stack direction={"row"} spacing={2}>
          <Button variant="text">Totali:</Button>
          <Button variant="text">Peso: {totRows.Peso} (kg)</Button>
          <Button variant="text">Qtà: {totRows.Quantita}</Button>
          <Button variant="text">Volume: {totRows.Volume} (mc)</Button>
          <Button variant="contained" color="primary" onClick={() => addDettaglioOrdine()} startIcon={<Add />}>
            Aggiungi
          </Button>
        </Stack>
      </CardActions>
    </Card>
  )
}

export default DettaglioMisureMerce