import React, { useContext, useState, useEffect } from 'react'
import { AuthContext, PreventiviNewContext } from "../../context";
import { AssignmentTwoTone } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  Switch,
  Stack,
  Alert,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Button,
  TableHead,
  TableContainer,
  CardActions,
  AlertTitle,
  Box,
} from "@mui/material";
import { orange } from '@mui/material/colors';



const RiepilogoPreventivo = () => {

  const [riepilogoChecked, setRiepilogoChecked] = useState(false/*nuovoPreventivoForm.sceltaContrassegno*/);


  const {
    state: {
      form,
      result
    },
    popolaNuovoPreventivo
  } = useContext(PreventiviNewContext);


  useEffect(() => {
    popolaNuovoPreventivo(JSON.parse(localStorage.getItem("preventivo_form")));
  }, [])

  function accessoriMittente() {

    let ret = ""
    for (let i = 0; i < form.IndicatoriMittente.length; i++) {
      ret = ret + `<br>${form.IndicatoriMittente[i]}`
    }
    return (ret);
  }

  function onlyMerce(){
    if ((form.ImportoCOD !== "" || form.StimaValMerce !== " ") || form.ordadr.length > 0){
      return false
    }

    if(form?.indicatoriDestinatario?.length > 0 || form?.indicatoriMittente?.length > 0){
      return false
    }

    return true
  }

  function accessoriDestinatario() {

    let ret = ""
    for (let i = 0; i < form.indicatoriDestinatario.length; i++) {
      ret = ret + `<br>${form.indicatoriDestinatario[i]}`
    }
    return (ret);
  }



  function contrassegnoData() {
    return (
      <CardActions sx={{ justifyContent: "right", direction: "row" }}>
        <Stack direction={"row"} spacing={2}>
          <Button variant="text"><strong>Contrassegno:</strong></Button>
          <Button variant="text">Tipo Incasso: {form.TipoIncassoCOD}</Button>
          <Button variant="text">Valuta: {form.ValutaCOD}</Button>
          <Button variant="text">Importo: {form.ImportoCOD} </Button>
        </Stack>
      </CardActions>

    )
  }

  function assicurazioneData() {
    return (
      <CardActions sx={{ justifyContent: "left", direction: "row" }}>
        <Stack direction={"row"} spacing={2}>
          <Button variant="text"><strong>Assicurazione:</strong></Button>
          <Button variant="text">Valuta: {form.StimaValMerceValuta}</Button>
          <Button variant="text">Importo: {form.StimaValMerce} </Button>
        </Stack>
      </CardActions>

    )
  }

  function merceADRTable() {
    return (
      <TableContainer>
        <strong>Riepilogo merce ADR</strong>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell align="right">Classe</TableCell>
              <TableCell>Colli</TableCell>
              <TableCell align="right">Imballaggio</TableCell>
              <TableCell align="right">Numero ONU</TableCell>
              <TableCell align="right">Peso&nbsp;(kg)</TableCell>
              <TableCell align="right">Descrizione</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {form.ordadr.map((row) => (
              <TableRow
                //   key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.Classe}
                </TableCell>
                <TableCell align="right">{row.Colli}</TableCell>

                <TableCell align="right">{row.Imballaggio}</TableCell>
                <TableCell align="right">{row.NumONU}</TableCell>
                <TableCell align="right">{row.Peso}</TableCell>
                <TableCell align="right">{row.Descrizione}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  function merceTable() {
    return (
      <TableContainer sx={{ width: 1 }}>
        <strong>Riepilogo merce</strong>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell align="right">Quantita</TableCell>
              <TableCell align="right">Peso&nbsp;(kg)</TableCell>
              <TableCell align="right">Altezza&nbsp;(m)</TableCell>
              <TableCell align="right">Lunghezza&nbsp;(m)</TableCell>
              <TableCell align="right">Larghezza&nbsp;(m)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {form.ordmerce.map((row) => (
              <TableRow
                //   key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.Tipo}
                </TableCell>
                <TableCell align="right">{row.Quantita}</TableCell>
                <TableCell align="right">{row.Peso}</TableCell>
                <TableCell align="right">{row.Altezza}</TableCell>
                <TableCell align="right">{row.Lunghezza}</TableCell>
                <TableCell align="right">{row.Larghezza}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <   Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.info.main + "20" }}
    >
      <CardHeader
        title={"Riepilogo"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<AssignmentTwoTone />}
        action={
          <FormControlLabel
            control={
              <Switch checked={riepilogoChecked} onClick={() => setRiepilogoChecked(!riepilogoChecked)} />
            }
            label=""
          />
        }
      />

      {riepilogoChecked ? (
        <Grow in={riepilogoChecked} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
          <CardContent>
            <Grid item container spacing={1}>
              {form?.ordmerce !== "" ? (
                <Grid item xs={12} md={(onlyMerce()) ? 12 : 6} lg={(onlyMerce())  ? 12 : 6}>
                  <Card sx={{background: orange[50],}} elevation={0}>
                    <CardContent>
                      {form.ordmerce ? merceTable() : ""}
                    </CardContent>
                  </Card>
                </Grid>
              ) : (
                <></>
              )}

              {form?.ordadr?.length > 0 ? (
                <Grid item xs={12} md={6} lg={6}>
                  <Card sx={{background: orange[50],}} elevation={0}>
                    <CardContent>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 10, md: 10 }}>
                      {form.ordadr.length > 0 ? merceADRTable() : ""}
                    </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ) : (
                <></>
              )}

              {(form?.ImportoCOD !== "" || form.StimaValMerce !== " ") ? (
                <Grid item xs={12} md={6} lg={6}>
                  <Card sx={{background: orange[50],}} elevation={0}>
                    <CardContent>
                      <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 2, sm: 10, md: 10 }}>
                        {form.ImportoCOD !== "" && contrassegnoData()}
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 2, sm: 10, md: 10 }}>
                      {form.StimaValMerce !== " " ? assicurazioneData() : ""}
                    </Stack>
                  </CardContent>
                </Card>      
                          </Grid>
              ) : (
                <></>
              )}

              {form?.IndicatoriMittente.length > 0 ? (
                <Grid item xs={12} md={3} lg={3}>
                  <Alert severity="info" icon={<></>} >
                    <AlertTitle><strong>Accessori Mittente</strong></AlertTitle>
                    {
                      <ReactMarkdown
                        children={accessoriMittente()}
                        rehypePlugins={[rehypeRaw]}
                      >{accessoriMittente()}</ReactMarkdown>
                    }{" "}
                  </Alert>
                </Grid>
              ) : (
                <></>
              )}

              {form?.indicatoriDestinatario.length > 0 ? (
                <Grid item xs={12} md={3} lg={3}>
                  <Alert severity="info" icon={<></>} >
                    <AlertTitle><strong>Accessori Destinatario</strong></AlertTitle>
                    {
                      <ReactMarkdown
                        children={accessoriDestinatario()}
                        rehypePlugins={[rehypeRaw]}
                      >{accessoriDestinatario()}</ReactMarkdown>
                    }{" "}
                  </Alert>
                </Grid>
              ) : (
                <></>
              )}

            </Grid>
          </CardContent>
        </Grow>

      ) : (
        <></>
      )}


    </Card>
  )
}

export default RiepilogoPreventivo