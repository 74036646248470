import createDataContext from "./createContext";
import { BaseService } from "../api";

const END_LOGIN = "end_login";
const LOGOUT = "logout";
const IS_VALID_ADD = "is_valid_add";

const INITIAL_STATE = {
  user: null,
  isValidAdd:null,
  configAll:{}
};


const authReducer = (state, action) => {
  switch (action.type) {
   
    case END_LOGIN: {
      const { user, configAll } = action.payload;
      return { ...state, user, configAll};
    }
    case LOGOUT: {
      return { ...INITIAL_STATE };
    }
    case IS_VALID_ADD: {
      return { ...state, isValidAdd: action.payload };
    }
  
    default: {
      return state;
    }
  }
};

const login = (dispatch) => async (loginForm, navigate, server, configUser, getNationsLists) => {  
      const response = await BaseService.post(`${server}/login`, {
      jwtusername: loginForm.username,
      jwtpassword: loginForm.password
    });
    if(response.data){
      const user = response.data;
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('UserDescrizione', response.data.UserDescrizione)
      if(response.data.LuogoRitiro !== undefined){
        localStorage.setItem('luogoRitiro', JSON.stringify(response.data.LuogoRitiro))
      }else{
        localStorage.setItem('luogoRitiro', JSON.stringify([]))
      }
      if(response.data.configurazione !== undefined){
        localStorage.setItem('configurazione', JSON.stringify(response.data.configurazione))
        let findRoute = response.data.configurazione.find((res, i)=> res.visible)
        navigate("/"+findRoute.name)
      }else{
        localStorage.setItem('configurazione', JSON.stringify([]))
        navigate("/")
      }
      dispatch({ type: END_LOGIN, payload: { user, configAll: configUser} });
    }
};

const logout = (dispatch) => async (navigate) => {
  navigate("/")
  localStorage.removeItem('token')
  localStorage.removeItem('UserDescrizione')
  localStorage.removeItem('configurazione')
  dispatch({ type: LOGOUT });
};

const addValidCheck = (dispatch) => async (param) => {
  dispatch({
    type: IS_VALID_ADD,
    payload: param,
  });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    login,
    logout,
    addValidCheck
  }, // actions
  INITIAL_STATE // initial state
);
