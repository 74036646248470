import React from "react";
import {
  Assicurazione,
  Contrassegno,
  Destinatario,
  DettaglioMisureMerce,
  MerceADR,
  Mittente,
  NotaDimensioneMerce,
  Servizio,
  TotaleMerce,
  UploadFile,
} from "./AgromentiOrdiniForm";

const PortoAssegnato = ({ configOrder, setMainForm, mainForm, scrollOnBott }) => {
  return (
    <>
      {configOrder.ritiri_assegnato.mittente.visible ? (
        <Mittente
          setMainForm={setMainForm}
          mainForm={mainForm}
          oraPronta={configOrder.ritiri_assegnato.orapronta}
          luogoRitiroVisible={
            configOrder.ritiri_assegnato.mittente.default_LuogoRitiro
          }
          riferimentoVisible={
            configOrder.ritiri_assegnato.mittente.riferimento_visible
          }
          readOnly={configOrder.ritiri_assegnato.mittente.readonly}
          indicatoriConfig={configOrder.ritiri_assegnato.mittenteindicatori}
        />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.destinatario.visible ? (
        <Destinatario
          setMainForm={setMainForm}
          mainForm={mainForm}
          luogoRitiroVisible={
            configOrder.ritiri_assegnato.destinatario.default_LuogoRitiro
          }
          riferimentoVisible={
            configOrder.ritiri_assegnato.destinatario.riferimento_visible
          }
          readOnly={configOrder.ritiri_assegnato.destinatario.readonly}
          indicatoriConfig={configOrder.ritiri_assegnato.destinatarioindicatori}
        />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.servizio.visible ? (
        <Servizio setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.dettagliomisuremerce.visible ? (
        <DettaglioMisureMerce setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.totalemerce.visible ? (
        <TotaleMerce setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.notadimensioni.visible ? (
        <NotaDimensioneMerce setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.uploadfile.visible ? (
        <UploadFile setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.contrassegno.visible ? (
        <Contrassegno setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.merceadr.visible ? (
        <MerceADR setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_assegnato.assicurazione.visible ? (
        <Assicurazione setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
    </>
  );
};

export default PortoAssegnato;
