import { Policy } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import { OrdiniRitiroContext } from "../../../context";
import { useContext } from "react";
import { useEffect } from "react";
import { CurrencySimbol } from "../../../utilities";
import { useForm } from "../../../hooks";

const Assicurazione = ({ mainForm, scollBott, setMainForm }) => {
  const [checked, setChecked] = useState(mainForm.sceltaAssicurazione);

  const {
    state: { valuteList },
    getValute,
  } = useContext(OrdiniRitiroContext);

  useEffect(() => {
    getValute();
  }, []);

  const {
    values: assicurazioneForm,
    changeHandler,
    setValues,
  } = useForm(
    {
      ValMerce: mainForm.ValMerce,
      ValMerceValuta: mainForm.ValMerceValuta,
    },
    []
  );

  useEffect(() => {
    if (!checked) {
      setValues({
        ...assicurazioneForm,
        ValMerce: "",
        ValMerceValuta: "EUR",
      });
    }
    //testCheck(checked)
    setValues({ ...assicurazioneForm, sceltaAssicurazione: checked });
  }, [checked]);

  useEffect(() => {
    setMainForm({
      ...mainForm,
      ValMerce: assicurazioneForm.ValMerce,
      ValMerceValuta: assicurazioneForm.ValMerceValuta,
    });
  }, [assicurazioneForm]);

  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.info.main + "20" }}
    >
      <CardHeader
        title={"Assicurazione"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<Policy />}
        action={
          <FormControlLabel
            control={
              <Switch checked={checked} onClick={() => setChecked(!checked)} />
            }
            label=""
          />
        }
      />
      {checked ? (
        <Grow in={checked} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
          <CardContent>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6} lg={6} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-from-nations">Valuta</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-from-nations"
                    id="select-from-nations"
                    value={assicurazioneForm.ValMerceValuta || ""}
                    onChange={(e) =>
                      changeHandler("ValMerceValuta", e.target.value)
                    }
                    label="Valuta"
                  >
                    <MenuItem value={" "}>Seleziona valuta</MenuItem>
                    {valuteList?.map((params, key) => (
                      <MenuItem key={key} value={params.codice}>
                        {params.codice} - {params.descrizione}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Valore merce"
                  autoComplete="off"
                  value={assicurazioneForm.ValMerce || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {CurrencySimbol(assicurazioneForm.ValMerce)}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    changeHandler("ValMerce", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Grow>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default Assicurazione;
