import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TrackingContext } from "../../context";
import { useForm } from "../../hooks";
import {
  Assicurazione,
  Contrassegno,
  DataRichiesta,
  Destinatario,
  DettaglioMerce,
  Epal,
  MerceADR,
  Servizi,
  TotaleDatiMerce,
} from "./ArgomentiSpedizioniForm";

const SpedizioniMainForm = () => {
  const navigate = useNavigate();
  const [title, setTitle] = React.useState("Nuova spedizione");

  const handleClose = () => {
    navigate(-1);
  };

  const {
    state: { configSped },
    getConfigModeSpedizioni,
    postSped,
  } = useContext(TrackingContext);
  
  useEffect(() => {
    
    getConfigModeSpedizioni()
    console.log(configSped);
  }, [])
  
  const {
    values: addSpedizioniForm,
    setValues,
  } = useForm(
    {
      UploadFile: [],
      DataPronta: moment().format("YYYY-MM-DD"),
      OraPronta: "",
      MittNazione: "IT",
      MittLocalita: "",
      MittProvincia: "",
      MittCAP: "",
      MittRagSoc: "",
      MittCodiceIndirizzoCodificato: "",
      MittIndirizzo: "",
      MittPersona: "",
      MittTelefono: "",
      MittEmail: "",
      MittRiferimento: "",
      NotaRitiro: "",
      Orari: "",
      IndicatoriMitt: [],

      DestNazione: "IT",
      DestLocalita: "",
      DestProvincia: "",
      DestCAP: "",
      DestRagSoc: "",
      DestCodiceIndirizzoCodificato: "",
      DestIndirizzo: "",
      DestPersona: "",
      DestTelefono: "",
      DestEmail: "",
      DestRiferimento: "",
      NotaConsegna: "",
      IndicatoriDest: [],

      DataRichiesta: null,

      ServizioPerWeb: " ",
      spedmerce: [],
      Tipo: " ",
      Peso: "",
      Volume: "",
      Quantita: "",
      Epal: null,

      NotaDimensioniMerce: "",
      spedadr: [],
      TipoIncasso: " ",
      Contrassegno: "",
      ContrassegnoValuta: "EUR",

      ValMerce: "",
      ValMerceValuta: "EUR",
      Resa: "",
      sceltaContrassegno: false,
      sceltaADR: false,
      sceltaAssicurazione: false,
    },
    []
  );

  return (
    <div>
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>

          <Stack direction={"row"} spacing={2}>
            <Button
              color="warning"
              size={"small"}
              variant={"contained"}
              onClick={handleClose}
            >
              annulla
            </Button>
            <Button
              color="success"
              size={"small"}
              variant={"contained"}
              onClick={() => postSped(addSpedizioniForm, handleClose, navigate)}
            >
              salva
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box pt={9} pb={1}>
        {configSped.destinatario?.visible ? (
          <Destinatario 
            setMainForm={setValues} 
            mainForm={addSpedizioniForm} 
            riferimentoCaption={configSped.destinatario?.riferimento?.caption}
            riferimentoVisible={configSped.destinatario?.riferimento_visible}
            luogoRitiroVisible={configSped.destinatario?.default_LuogoRitiro}
            readOnly={configSped.destinatario?.readonly}
            indicatoriConfig={configSped.destinatarioindicatori}
          />
        ) : (
          <></>
        )}
        {configSped.datarichiesta?.visible ? (
          <DataRichiesta setMainForm={setValues} mainForm={addSpedizioniForm} caption={configSped.datarichiesta.caption} />
        ) : (
          <></>
        )}
        {configSped?.servizio?.visible ? (
          <Servizi setMainForm={setValues} mainForm={addSpedizioniForm} servizioDefault={configSped.servizio.default}/>
        ) : (
          <></>
        )}
        {configSped.dettagliomisuremerce?.visible ? (
          <DettaglioMerce
            setMainForm={setValues}
            mainForm={addSpedizioniForm}
          />
        ) : (
          <></>
        )}
        {configSped.totalemerce?.visible ? (
          <TotaleDatiMerce
            setMainForm={setValues}
            mainForm={addSpedizioniForm}
          />
        ) : (
          <></>
        )}
        <Epal setMainForm={setValues} mainForm={addSpedizioniForm} />
        {configSped.contrassegno?.visible ? (
          <Contrassegno setMainForm={setValues} mainForm={addSpedizioniForm} />
        ) : (
          <></>
        )}
        {configSped.merceadr?.visible ? (
          <MerceADR setMainForm={setValues} mainForm={addSpedizioniForm} />
        ) : (
          <></>
        )}
        {configSped.assicurazione?.visible ? (
          <Assicurazione setMainForm={setValues} mainForm={addSpedizioniForm} />
        ) : (
          <></>
        )}
        <Stack direction={"row-reverse"} spacing={2} mt={2}>
            <Button
              color="success"
              size={"medium"}
              variant={"contained"}
              onClick={() => postSped(addSpedizioniForm, handleClose, navigate)}
            >
              salva
            </Button>
            <Button
              color="warning"
              size={"medium"}
              variant={"contained"}
              onClick={handleClose}
            >
              annulla
            </Button>
          </Stack>
      </Box>
    </div>
  );
};

export default SpedizioniMainForm;
