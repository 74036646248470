import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Zoom,
} from "@mui/material";
import {
  LocalShippingTwoTone,
} from "@mui/icons-material";
import { useContext } from "react";
import { DashboardContext } from "../../context";
import { iconStateTracking } from "../../utilities";

export const OrdiniMese = ({ mesecorrente, meseprecedente }) => {
  const {
    state: { loadRitiri },
  } = useContext(DashboardContext);

  return (
    <Card sx={{ height: "100%" }} elevation={loadRitiri ? 3 : 1}>
      <CardHeader
        title={
          loadRitiri ? (
            `Ritiri`
          ) : (
            <Skeleton animation="wave" height={40} width="50%" />
          )
        }
        action={
          loadRitiri ? (
            <Zoom
              in={loadRitiri}
              timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
            >
              <Avatar
                sx={{
                  backgroundColor: "warning.main",
                  height: 56,
                  width: 56,
                }}
              >
                <LocalShippingTwoTone />
              </Avatar>
            </Zoom>
          ) : (
            <Skeleton
              animation="wave"
              variant="circular"
              width={56}
              height={56}
            />
          )
        }
      />
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          {loadRitiri ? (
            <Grow
              in={loadRitiri}
              timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
            >
              <Grid container>
                <Grid item xs={12} md={6} lg={6}>
                  <Divider textAlign="left">
                    {mesecorrente.in_fase_ritiro?.Month.toUpperCase()}
                  </Divider>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {iconStateTracking("INR", "In fase di ritiro")}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="In fase di ritiro"
                        secondary={mesecorrente.in_fase_ritiro?.Value}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{iconStateTracking("RIT", "ritirati")}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Ritirati"
                        secondary={mesecorrente.ritirati?.Value}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Divider textAlign="left">
                    {meseprecedente.in_fase_ritiro?.Month.toUpperCase()}
                  </Divider>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {iconStateTracking("INR", "In fase di ritiro")}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="In fase di ritiro"
                        secondary={meseprecedente.in_fase_ritiro?.Value}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{iconStateTracking("RIT", "ritirati")}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Ritirati"
                        secondary={meseprecedente.ritirati?.Value}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grow>
          ) : (
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
